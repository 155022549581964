<template>
  <profiles class="col-6"/>
  <ad-accounts/>
</template>

<script lang="ts">
  import { defineComponent, onMounted } from "vue";
  import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
  import Profiles from './Profiles.vue';
  import AdAccounts from './AdAccounts.vue';

  export default defineComponent({
    name: "FacebookIntegration",

    components: {
      Profiles,
      AdAccounts,
    },

    setup() {
      onMounted(() => {
        setCurrentPageTitle("Facebook Integration");
      });

      return {};
    }
  });
</script>
