<template>
  <div class="card">
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1">Profiles</span>
      </h3>
      <div class="card-toolbar">
        <button class="btn btn-sm btn-primary" ref="addProfileButton" @click="addProfile">
          <span class="indicator-label">Add profile</span>
          <span class="indicator-progress">Connecting <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
        </button>
      </div>
    </div>

    <div class="card-body py-3">
      <div class="table-responsive">
        <overlay-spinner v-if="loading"/>

        <table v-else class="table table-row-bordered align-middle gs-0 gy-3">
          <tbody>
            <tr v-for="(profile, index) in profiles" :key="`profile-row-${index}`">
              <td class="d-flex">
                <div>
                  <div class="symbol symbol-50px me-5">
                    <img :src="profile.avatar" class="h-75 align-self-end"/>
                  </div>
                </div>
                <div>
                  <div class="text-dark fw-bolder text-hover-primary mb-1 mt-1 fs-6">{{ profile.name }}</div>
                  <span class="text-muted fw-bold d-block">ID: {{ profile.id }}</span>
                </div>
              </td>
              <td>
                <button class="btn btn-sm btn-light-primary btn-active-primary"
                        v-if="canReconnectProfile(profile)"
                        :id="`fb-profile-reconnect-btn-${profile.id}`"
                        @click="reconnectProfile(profile)">
                  <span class="indicator-label">Reconnect</span>
                  <span class="indicator-progress">Connecting <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                </button>
              </td>
              <td class="text-end">
                <button class="btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1" @click="deleteProfile(profile.id)">
                  <i class="fas fa-trash" />
                </button>

                <button v-if="!getSyncingFbProfileAdAccounts(profile.id)"
                        class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                        @click="syncProfileAdAccounts(profile)">
                  <i class="fas fa-sync"></i>
                </button>
                <button v-else
                        class="btn btn-sm btn-icon btn-light-dark"
                        :disabled="true">
                  <i class="spinner-border text-success" />
                </button>
              </td>
            </tr>

            <tr v-if="profiles.length === 0">
              <td colspan="3" class="text-center">
                <div class="alert alert-secondary">Add profile to fetch ad accounts</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {facebookApiInit} from '@/core/plugins/facebook.js';
import PopupMessage from '@/core/helpers/PopupMessage';
import store from '@/store';
import {Actions} from '@/store/enums/StoreEnums';
import ConfirmDialog from '@/core/helpers/ConfirmDialog';
import {mapGetters} from 'vuex';
import {FacebookIntegrationProfile} from '@/store/modules/integrations/FacebookIntegrationModule';
import dayjs, {Dayjs} from 'dayjs';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import OverlaySpinner from '@/components/OverlaySpinner.vue';

dayjs.extend(utc);
dayjs.extend(timezone);

export default defineComponent({
  name: "FacebookProfiles",

  components: {OverlaySpinner},

  data() {
    return {
      fbLoginOpened: false,
    };
  },

  computed: {
    ...mapGetters({
      profiles: 'getFbProfiles',
      loading: 'getLoadingFbProfiles',
      getSyncingFbProfileAdAccounts: 'getSyncingFbProfileAdAccounts',
    }),
  },

  mounted() {
    facebookApiInit();
    store.dispatch(Actions.FETCH_FB_PROFILES);
  },

  methods: {
    addProfile() {
      const self = this;
      (this.$refs.addProfileButton as HTMLElement).setAttribute('data-kt-indicator', 'on');

      window.FB.getLoginStatus(function(response) {
        if (response && response.status === 'connected') {
          window.FB.logout(function() {
            self.connectToFbProfile();
          });
        } else {
          self.connectToFbProfile();
        }
      });
    },

    connectToFbProfile() {
      if (this.fbLoginOpened) return;
      this.fbLoginOpened = true;

      window.FB.login(
        (response) => {
          if (response && response.status === 'connected') {
            store.dispatch(Actions.ADD_FB_PROFILE, response.authResponse.accessToken)
              .then(response => {
                if (response.data.status === 'already_exists') {
                  PopupMessage.show('Profile was already added', 'warning', 'Ok');
                }
                else if (response.data.status === 'restored') {
                  PopupMessage.show('Restored existing facebook profile that was previously deleted', 'info', 'Ok');
                }
                store.dispatch(Actions.FETCH_FB_AD_ACCOUNTS);
              })
              .finally(() => {
                this.fbLoginOpened = false;
                (this.$refs.addProfileButton as HTMLElement).removeAttribute('data-kt-indicator');
              });
          } else {
            this.fbLoginOpened = false;
            PopupMessage.show('Failed to connect to facebook account', 'warning', 'Ok');
            (this.$refs.addProfileButton as HTMLElement).removeAttribute('data-kt-indicator');
          }
        },
        {
          // scope: 'public_profile,email,ads_management,ads_read', // @deprecated, config is used instead
          config_id: process.env.VUE_APP_FACEBOOK_APP_CONFIG_ID
        }
      );
    },

    reconnectProfile(profile: FacebookIntegrationProfile) {
      const self = this;
      (document.getElementById(`fb-profile-reconnect-btn-${profile.id}`) as HTMLElement).setAttribute('data-kt-indicator', 'on');

      PopupMessage.show(`Login to Facebook account with ${profile.email} email`, 'info', 'Ok')
        .then(() => {
          window.FB.getLoginStatus(function(response) {
            if (response && response.status === 'connected') {
              window.FB.logout(function() {
                self.reconnect(profile);
              });
            } else {
              self.reconnect(profile);
            }
          });
        });
    },

    reconnect(profile: FacebookIntegrationProfile) {
      if (this.fbLoginOpened) return;
      this.fbLoginOpened = true;

      window.FB.login(
        (response) => {
          if (response && response.status === 'connected') {
            store.dispatch(Actions.RECONNECT_FB_PROFILE, {accessToken: response.authResponse.accessToken, profileId: profile.id})
              .then(response => {
                if (response.data.status === 'success') {
                  PopupMessage.show('Profile was reconnected successfully', 'success', 'Ok');

                  store.dispatch(Actions.FETCH_FB_AD_ACCOUNTS);
                }
                else if (response.data.status === 'invalid_account_used') {
                  PopupMessage.show('You have tried to reconnect with different Facebook account', 'error', 'Try again');
                }
              })
              .finally(() => {
                this.fbLoginOpened = false;
                (document.getElementById(`fb-profile-reconnect-btn-${profile.id}`) as HTMLElement).removeAttribute('data-kt-indicator');
              });
          } else {
            this.fbLoginOpened = false;
            PopupMessage.show('Failed to reconnect to facebook account', 'warning', 'Ok');
            (document.getElementById(`fb-profile-reconnect-btn-${profile.id}`) as HTMLElement).removeAttribute('data-kt-indicator');
          }
        },
        {
          // scope: 'public_profile,email,ads_management,ads_read', // @deprecated, config is used instead
          config_id: process.env.VUE_APP_FACEBOOK_APP_CONFIG_ID
        }
      );
    },

    deleteProfile(id) {
      ConfirmDialog.show({text: 'Are you sure?', title: 'Delete Facebook profile', type: 'error', confirmButtonText: 'Delete', icon: 'warning'})
        .then(confirmed => {
          if (confirmed) {
            store.dispatch(Actions.DELETE_FB_PROFILE, id)
              .then(() => {
                PopupMessage.show('Profile was deleted successfully', 'success', 'Ok');
                store.dispatch(Actions.FETCH_FB_AD_ACCOUNTS);
              });
          }
        });
    },

    syncProfileAdAccounts(profile) {
      store.dispatch(Actions.SYNC_FB_PROFILE_AD_ACCOUNTS, profile.id);
    },

    canReconnectProfile(profile) {
      if(profile.has_expired) return true;

      const expiresAt = profile.expires_at;
      return dayjs().isAfter(expiresAt.subtract(7, 'days'));
    },
  },
});
</script>
