<template>
  <div class="card">
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1">Ad Accounts</span>
      </h3>
      <div class="card-toolbar"></div>
    </div>

    <div class="card-body py-3">
      <div class="table-responsive">
        <table class="table table-row-bordered align-middle gy-5 gx-0">
          <thead>
            <tr class="fw-bold fs-6 text-gray-900 border-bottom-2 border-gray-200">
              <th class="min-w-150px">Ad account</th>
              <th class="min-w-150px"></th>
              <th class="min-w-150px">Spend last 28 days</th>
              <th class="min-w-140px">Linked store</th>
            </tr>
          </thead>

          <overlay-spinner v-if="loading"/>

          <tbody v-else>
            <tr v-for="(item, index) in adAccounts" :key="`profile-row-${index}`">
              <td>
                <div class="symbol symbol-50px me-4 d-inline-block">
                  <label class="form-check form-switch form-check-custom form-check-solid" @click="toggleItem(item)">
                    <input class="form-check-input" type="checkbox" value="" :checked="item.is_active"/>
                  </label>
                </div>
                <div class="d-inline-block">
                  <span class="text-dark fw-bolder text-hover-primary mb-1 fs-6">{{ item.name }}</span>
                  <span class="text-muted fw-bold d-block">{{ item.id }}</span>
                </div>
              </td>
              <td>
                <span class="badge badge-danger me-2" v-if="item.status === 'DISABLED'">Disabled</span>
              </td>
              <td>
                {{ item.insights_sum_spend ? $filters.money(parseFloat(item.insights_sum_spend), 0) : 'N/A' }}
              </td>
              <td>
                  {{ item.shopify_shop ? (item.shopify_shop.shop_name || item.shopify_shop.shopify_domain || 'N/A') : 'N/A' }}
              </td>
            </tr>

            <tr v-if="adAccounts.length === 0">
              <td colspan="4" class="text-center">
                <div class="alert alert-secondary">There are no items to show</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {mapGetters} from 'vuex';
import store from '@/store';
import {Actions} from '@/store/enums/StoreEnums';
import OverlaySpinner from '@/components/OverlaySpinner.vue';
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "FacebookProfiles",

  components: {OverlaySpinner},

  computed: {
    ...mapGetters({
      adAccounts: 'getFbAdAccounts',
      loading: 'getLoadingFbAdAccounts',
    }),
  },

  mounted() {
    store.dispatch(Actions.FETCH_FB_AD_ACCOUNTS);
  },

  methods: {
    toggleItem(item) {
      const isEnabling = !item.is_active;
      store.dispatch(Actions.TOGGLE_ACTIVITY_FB_AD_ACCOUNT, item).then(() => {
        if(isEnabling) {

          Swal.fire({
            title: `<span>Successfully enabled Ad Account<br/> <strong>${item.name}</strong></span>`,
            html: 'Please note that it may take up to an hour for data to be properly synced and shown for this account',
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: 'Ok',
            customClass: {
              confirmButton: "btn fw-bold btn-light-success",
            }
          });
        }
      });
    },
  },
});
</script>
