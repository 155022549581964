
import {defineComponent} from "vue";
import {mapGetters} from 'vuex';
import store from '@/store';
import {Actions} from '@/store/enums/StoreEnums';
import OverlaySpinner from '@/components/OverlaySpinner.vue';
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "FacebookProfiles",

  components: {OverlaySpinner},

  computed: {
    ...mapGetters({
      adAccounts: 'getFbAdAccounts',
      loading: 'getLoadingFbAdAccounts',
    }),
  },

  mounted() {
    store.dispatch(Actions.FETCH_FB_AD_ACCOUNTS);
  },

  methods: {
    toggleItem(item) {
      const isEnabling = !item.is_active;
      store.dispatch(Actions.TOGGLE_ACTIVITY_FB_AD_ACCOUNT, item).then(() => {
        if(isEnabling) {

          Swal.fire({
            title: `<span>Successfully enabled Ad Account<br/> <strong>${item.name}</strong></span>`,
            html: 'Please note that it may take up to an hour for data to be properly synced and shown for this account',
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: 'Ok',
            customClass: {
              confirmButton: "btn fw-bold btn-light-success",
            }
          });
        }
      });
    },
  },
});
